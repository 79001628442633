import React from 'react'
import Categories from './Categories/Category'

const CategoryShow = () => {
  return (
    <div>
        <Categories/>
    </div>
  )
}

export default CategoryShow