import React from 'react'

import DeliveryContent from '../Components/DeliveryContent'

const DeliveryScreen = () => {
  return (
    <div>
        
        <DeliveryContent/>
        
    </div>
  )
}

export default DeliveryScreen