import React from 'react'

import CartBody from '../Components/CartBody'


const CartScreen = () => {
    
   return (
    <div>
  
            <CartBody />
   
   </div>
  )
}

export default CartScreen