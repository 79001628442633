import React from 'react'
import About from '../Components/About'

const AboutScreen = () => {
  return (
    <div>
        
        <About/>
        
    </div>
  )
}

export default AboutScreen