import React from 'react'

import './Description.css'
const Description = () => {
  return (
    <div>
        <div className='desc-content'>
            <p>
                Here ,its described to you the terms of this promotion.What the promotion is all about
                 and whats involved in the package.<br/>
                 -Maximize your Chances by making more orders in a week.
            </p>
        </div>
        <div className='eligibility'>
            <h6>Eligibility :<br/><span>You must Log in to see this Feature</span></h6>
            <button>Check Now</button>
        </div>
    </div>
  )
}

export default Description
