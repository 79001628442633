import React from 'react'
import ShopContent from '../Components/ShopContent'

const ShopScreen = () => {
  return (
    <div>
    
        {/* Banner Shop Now */}

        {/* Body */}
        <ShopContent/>
        
      
    </div>
  )
}

export default ShopScreen
