import React from 'react'
import HeadSection from './about/HeadSection'

const About = () => {
  return (
    <div>
        <HeadSection/>
        
    </div>
  )
}

export default About